import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {addFavorito, delFavorito, editFavorito, getFavoritos} from "../../services";
import {AppState} from './rootReducer';

type TFavorito = {
    id:number,
    identificador:string,
    nombre:string,
    analisisDashboard:string,
}
interface StateFavorito {
    loading:boolean,
    opened:boolean,
    favId?:number,
    list: TFavorito[]
}
const initialState: StateFavorito = {
    loading:false,
    opened:false,
    favId:undefined,
    list: []
}




export const mostrarFavorito = createAsyncThunk(
    'mostrarFavorito',
    async (id:number|undefined, thunkAPI) => {
        let {id: userId = id} = (thunkAPI.getState() as AppState)?.auth.user ?? {};
        // try {
            let {data} = await getFavoritos(userId);

            let m = (data?.data ?? [])?.map((d:any)=> ({
                ...d?.attributes?.indicador_informes?.data,
            }))

            m = Object.keys(m[0]).map((i:any)=>({
                id: m[0][i].id,
                ...m[0][i]?.attributes
            }))
            return {list: m as TFavorito[], id:data.data[0]?.id};
        // }catch(e) {
        //
        // }


    }
);
export const marcarDesmarcarFavorito = createAsyncThunk(
    'marcarDesmarcarFavorito', async (params: { informeId:number }, thunkAPI) => {
        const {informeId} = params;
        let {id: userId} = (thunkAPI.getState() as AppState)?.auth.user ?? {};
        let {list: favoritos, favId} = (thunkAPI.getState() as AppState)?.favoritos ?? {};

        if(favId){

        }



        let informesIDs = new Set(favoritos.map(i=>i.id))

        if(informesIDs.has(informeId)){
            informesIDs.delete(informeId)
        }else{
            informesIDs.add(informeId)
        }



        const response = await (favId ? editFavorito(favId,userId,Array.from(informesIDs)): addFavorito(userId,Array.from(informesIDs))) ;

        if (response.status === 200 || response.status === 204) {
            thunkAPI.dispatch(mostrarFavorito())
        };
        return {data: response.data};
    }
);


export const eliminarFavorito = createAsyncThunk(
    'Favorito/eliminar', async (id: number) => {
        await delFavorito(id);
        // if (response.status === 200 || response.status === 204) historyHelper.back();
        return id;
    }
);




const slice = createSlice({
    name: 'themeSetting',
    initialState,
    reducers: {
        toggleOpen(state) {
            state.opened = !state.opened;
        }
    },
    extraReducers: builder => {

        //mostrarFavorito
        builder.addCase(mostrarFavorito.pending, (state, action) => {
            // state.status = "loading"
        })
        builder.addCase(mostrarFavorito.fulfilled, (state, action) => {
            const {list, id} = action.payload;

            state.favId = id
            state.list = list
        })
        builder.addCase(mostrarFavorito.rejected, (state, action) => {
            // state.status = "idle"
        })
        //marcarDesmarcarFavorito
        builder.addCase(marcarDesmarcarFavorito.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(marcarDesmarcarFavorito.fulfilled, (state, action) => {
            state.loading = false
            // state.list = action.payload;
        })
        builder.addCase(marcarDesmarcarFavorito.rejected, (state, action) => {
            state.loading = false
        })
        //eliminarFavorito
        builder.addCase(eliminarFavorito.pending, (state, action) => {
            // state.status = "loading"
        })
        builder.addCase(eliminarFavorito.fulfilled, (state, action) => {
            let id = action.payload
            // state.status = "idle"
            state.list = state.list?.filter(i=>i.id !== id);
        })
        builder.addCase(eliminarFavorito.rejected, (state, action) => {
            // state.status = "idle"
        })
    }

});
export const {toggleOpen} = slice.actions;

export default slice.reducer;
