import history from "../helpers/history";
import {store} from '../redux/store';
import Notifications from "react-notification-system-redux";
import {errorToNotifObj, getLSLang} from "../helpers";

import {getIdToken, getTokenInfo} from './auth';
import axios from "axios";

// let getIdToken = () => "bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NCwiaWF0IjoxNzEwOTc3NTczLCJleHAiOjE3MTM1Njk1NzN9.QUh8YE0jCmzQ4Kp3v6sdQYx2m_x1e1av7nBhgzlzpes"
let base_url = "https://sigqa.banfondesa.com.do/api"; //DESARROLLO
// let base_url = "https://personasbf.banfondesa.net/api/"; //PRODUCCIÓN

export {base_url};
export const API = axios.create({
    baseURL: base_url,
    // timeout: 1000,
    // headers: { 'contentType': 'application/json; charset=utf-8', "accept-language": "es-US" }
})
export const API_PUBLIC = axios.create({
    baseURL: base_url,
    // headers: { 'contentType': 'application/json; charset=utf-8', "accept-language": "es-US" }
})
API_PUBLIC.interceptors.request.use(function (request) {
    request.headers["accept-language"] = getLSLang("l") === "en" ? "en-US" : "es-ES";
    // request.headers["X-Frame-Options"] = "deny";
    // request.headers["x-forwarded-proto"] = "https";
    return request;
})
API_PUBLIC.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    return Promise.reject(error);
});

API.interceptors.request.use(function (request) {
    // request.headers["accept-language"] = getLSLang("l") === "en" ? "en-US" : "es-ES";
    // request.headers["X-Frame-Options"] = "deny";
    // request.headers["x-forwarded-proto"] = "https";

    const tokenId = getIdToken();
    if (tokenId) request.headers['Authorization'] = `Bearer ${tokenId}`;
    return request;
})
API.interceptors.response.use(function (response) {
    //notificaciones en redux
    let reduxNot = ["favoritos","alt-notificaciones-usuarios"];
    let url = response.config.url?.split("?")[0]?.replaceAll("/","").replaceAll(/[0-9]/g, '').toLowerCase();

    if(!!url && !reduxNot.includes(url)){
        let msj;
        switch (response.config.method) {
            case "post":
                msj = "Registro guardado";
                break
            case "put":
                msj = "Registro actualizado";
                break
            case "delete":
                msj = "Registro eliminado";
                break
            default:
                msj = "";
        }
        if (msj)
            store.dispatch(Notifications.success({title: msj + ' con exito.', autoDismiss: 4}));
    }

    return response;
}, function (error) {
    let url = error.response.config.url.toLowerCase();

    if(url === "profile") return Promise.reject(error);
    if (error.response.data) {
        if (error.response.status === 401) {
            localStorage.removeItem('_token');
            // history.replace("/banfondesa2/login", { backurl: history.location.pathname })
            history.replace("/login", { backurl: history.location.pathname })
        } else if (error.response.status === 403) {
            store.dispatch(Notifications.warning({ title: 'Acceso denegado', message: error.response?.data?.error ?? 'Necesita permisos para realizar esta acción.', autoDismiss: 5 }));
        }else {
            let obj = errorToNotifObj(error.response.data?.error)
            store.dispatch(Notifications.error(obj));
        }
    }else{
            store.dispatch(Notifications.error(errorToNotifObj(error.response)));
    }
    return Promise.reject(error);
});

//Profile
export function getPerfil() {
    let idUser = getTokenInfo().id
    return API.get(`users/${idUser}?populate[grupo_usuario][fields][0]=id&populate[grupo_usuario][fields][1]=grupo&fields[0]=id&fields[1]=username&fields[2]=email&fields[3]=provider&fields[4]=confirmed&fields[5]=blocked`);
    // return API.get(`/users/me`);
}
export function getNenus(grupoId) {
    return API.get(`/menus/grupo-usuario/${grupoId}`);

// return API.get(`menus?filters[nivel][$eq]=1&filters[grupo_usuarios][id][$eq]=${grupoId}&populate[indicador_informe][fields][0]=id&populate[indicador_informe][fields][1]=identificador&populate[submenus][fields][0]=id&populate[submenus][fields][1]=nombre&populate[submenus][fields][2]=nivel&populate[submenus][fields][3]=url&populate[submenus][populate][indicador_informe][fields][0]=id&populate[submenus][populate][indicador_informe][fields][1]=identificador&populate[submenus][populate][submenus][fields][0]=id&populate[submenus][populate][submenus][fields][1]=nombre&populate[submenus][populate][submenus][fields][2]=nivel&populate[submenus][populate][submenus][fields][3]=url&populate[submenus][populate][submenus][populate][indicador_informe][fields][0]=id&populate[submenus][populate][submenus][populate][indicador_informe][fields][1]=identificador&fields[0]=id&fields[1]=nombre&fields[2]=nivel&fields[3]=url&pagination[limit]=200`);
}
export function getNenuById(id,grupoId) {

    return API.get(`/menus/${id}/${grupoId}`);
    // // return API.get(`menus?filters[nivel][$eq]=1&filters[grupo_usuarios][id][$eq]=${grupoId}&populate[indicador_informe][fields][0]=id&populate[indicador_informe][fields][1]=identificador&populate[submenus][fields][0]=id&populate[submenus][fields][1]=nombre&populate[submenus][fields][2]=nivel&populate[submenus][fields][3]=url&populate[submenus][populate][indicador_informe][fields][0]=id&populate[submenus][populate][indicador_informe][fields][1]=identificador&populate[submenus][populate][submenus][fields][0]=id&populate[submenus][populate][submenus][fields][1]=nombre&populate[submenus][populate][submenus][fields][2]=nivel&populate[submenus][populate][submenus][fields][3]=url&populate[submenus][populate][submenus][populate][indicador_informe][fields][0]=id&populate[submenus][populate][submenus][populate][indicador_informe][fields][1]=identificador&fields[0]=id&fields[1]=nombre&fields[2]=nivel&fields[3]=url&pagination[limit]=200`);
    // return API.get(`/menus/${id}?populate[indicador_informe][fields][0]=id&populate[indicador_informe][fields][1]=identificador&populate[menu_padre][fields][0]=id&populate[menu_padre][fields][1]=nombre&populate[menu_padre][fields][2]=nivel&populate[menu_padre][populate][indicador_informe][fields][0]=id&populate[menu_padre][populate][indicador_informe][fields][1]=identificador&populate[menu_padre][populate][menu_padre][fields][0]=id&populate[menu_padre][populate][menu_padre][fields][1]=nombre&populate[menu_padre][populate][menu_padre][fields][2]=nivel&populate[menu_padre][populate][menu_padre][populate][indicador_informe][fields][0]=id&populate[menu_padre][populate][menu_padre][populate][indicador_informe][fields][1]=identificador&populate[submenus][fields][0]=id&populate[submenus][fields][1]=nombre&populate[submenus][fields][2]=nivel&populate[submenus][fields][3]=url&populate[submenus][populate][indicador_informe][fields][0]=id&populate[submenus][populate][indicador_informe][fields][1]=identificador&fields[0]=id&fields[1]=nombre&fields[2]=nivel&fields[3]=url`);
}
export function getDestacados() {

    return API.get(`/destacados?sort[0]=orden:asc&populate[imagen]=*&populate[indicador_informe][fields][0]=id&populate[indicador_informe][fields][1]=identificador&populate[configuracion][fields][0]=nombre_configuracion&populate[configuracion][fields][1]=alto_franja&populate[configuracion][fields][2]=alto_franja_es_porcentaje&populate[configuracion][fields][3]=color_fondo&populate[configuracion][fields][4]=tamano_texto_titulo&populate[configuracion][fields][5]=tamano_texto_descripcion&populate[configuracion][fields][6]=tiene_titulo&populate[configuracion][fields][7]=tiene_descripcion&populate[configuracion][fields][8]=ancho_texto&populate[configuracion][fields][9]=tiene_imagen&populate[configuracion][fields][10]=imagen_es_icono&populate[configuracion][fields][11]=posicion_imagen&populate[configuracion][fields][12]=tiene_boton&populate[configuracion][fields][13]=texto_boton&populate[configuracion][fields][14]=color_texto_boton&populate[configuracion][fields][15]=color_fondo_boton&populate[configuracion][fields][16]=color_contorno_boton&populate[configuracion][fields][17]=fondo_boton_transparente&populate[configuracion][fields][18]=margen_titulo_descripcion_boton&populate[configuracion][fields][19]=secciones_por_franja&populate[configuracion][fields][20]=estilo_fanja&populate[configuracion][fields][21]=posicion_texto&populate[configuracion][fields][22]=color_titulo&populate[configuracion][fields][23]=color_descripcion&populate[configuracion][fields][24]=color_hover_boton&fields[0]=id&fields[1]=identificador&fields[2]=titulo&fields[3]=detalle&fields[4]=url&fields[5]=orden`);
}
export function getIndicadoresTarjeta() {
    const valores =  API.get(`/indicador-tarjetas`);

    return valores;
    //?populate[indicador_informe][fields][0]=identificador&fields[0]=identificador&fields[1]=nombre&fields[2]=valor&fields[3]=es_porcentaje&fields[4]=crecimientoDiario&fields[5]=crecimientoMensual&pagination[limit]=200
}

// export function getIndicadorInforme(id){
//     return API.get(`/indicador-informes/${id}?fields[0]=identificador&fields[1]=urlDashboard&fields[2]=analisisDashboard&fields[3]=nombre&populate[menu][fields][0]=id&populate[menu][fields][1]=nombre&populate[menu][fields][2]=nivel&populate[menu][populate][menu_padre][fields][0]=id&populate[menu][populate][menu_padre][fields][1]=nombre&populate[menu][populate][menu_padre][fields][2]=nivel`)
// }
export function getIndicadorInforme(id,id_usuario){
    return API.get(`/indicador-informe/${id}/${id_usuario}`)
}

export function getIndicadoresRentabilidad(){
    return API.get("/indicadore-rentabilidad")
}
export function getSearchList(search) {

    return API.get(`/fuzzy-search/search?query=${search}&filters[indicador-informes][publishedAt][$notNull]=true`);
}
export function getFavoritos(userId) {

    return API.get(`/favoritos?filters[user][id][$eq]=${userId}&populate[user][fields][0]=id&populate[user][fields][1]=username&populate[user][fields][2]=email&populate[indicador_informes][fields][0]=id&populate[indicador_informes][fields][1]=identificador&populate[indicador_informes][fields][2]=nombre&populate[indicador_informes][fields][3]=analisisDashboard&fields[0]=id&pagination[limit]=100`);
}
export function addFavorito(userId=4, informeIDs=[1,2]) {

    return API.post(`/favoritos`,{data:{
            "user": userId,
            "indicador_informes": informeIDs
        }});
}
export function editFavorito(id= 1, userId=4, informeIDs=[1,2]) {

    return API.put(`/favoritos/${id}`,{data:{
            "user": userId,
            "indicador_informes": informeIDs
        }});
}
export function delFavorito(id) {

    return API.delete(`/favoritos/${id}`);
}

export function getNotificaciones(userId) {

    return API.get(`/alt-notificaciones-usuarios?sort[0]=fecha_notificacion:desc&sort[1]=updatedAt:desc&filters[usuario][id][$eq]=${userId}&filters[leida][$eq]=false&populate[indicador_informe][fields][0]=id&pagination[start]=0&pagination[limit]=50`);
}
export function addNotificacion(userId=4, informeIDs=[1,2]) {

    return API.post(`/notificaciones`,{data:{
            "user": userId,
            "indicador_informes": informeIDs
        }});
}
export function editNotificacion(id= 1, userId=4, informeIDs=[1,2]) {

    return API.put(`/notificaciones/${id}`,{data:{
            "user": userId,
            "indicador_informes": informeIDs
        }});
}
export function updateNotificacionUsuario(id) {

    return API.put(`/alt-notificaciones-usuarios/${id}`,{data:{
            "leida": true,
            "fecha_leido": new Date()
        }});
}
export function getConfiguracionesNotificacion(id) {
    return API.get(`/alt-configuraciones-usuarios?filters[usuario][id][$eq]=${id}`);
}
export function addConfiguracionesNotificacion(params) {
    return API.post(`/alt-configuraciones-usuarios`,{data:{
            ...params
        }});
}
export function editConfiguracionesNotificacion({id, ...params}) {
    return API.put(`/alt-configuraciones-usuarios/${id}`,{data:{
            ...params
        }});
}
export function delNotificacion(id) {

    return API.delete(`/notificaciones/${id}`);
}
