import { createSlice,createAsyncThunk,PayloadAction } from '@reduxjs/toolkit'
import { getPerfil} from '../../services';
import {IUser, IUserState} from '../types/IUser';
import history from "../../helpers/history";
import jwt_decode from "jwt-decode";
import {mostrarFavorito} from "./favoritosReducer";


const initialState:IUserState = {
    status:"idle",
    isAuthenticated: false,
    user: {
        role: "",
        token: "",
        email: "",
        userName: "",
        name: "",
        grupo_usuario: undefined,
    },
}

export const mostrarPerfil = createAsyncThunk(
    'mostrarPerfil', async (user:any|undefined, thunkAPI ) => {

        if(user && !!user.grupo_usuario?.id) {
            thunkAPI.dispatch(mostrarFavorito(user.id))

            return {
                id: user.id,
                email: user.email,
                userName: user.username,
                name: user.username,
                grupo_usuario: user.grupo_usuario,
            }
        }

        try {
            const response = await getPerfil()
            thunkAPI.dispatch(mostrarFavorito(response.data.id))
            return {
                id: response.data.id,
                email: response.data.email,
                userName: response.data.username,
                name: response.data.username,
                grupo_usuario: response.data.grupo_usuario,
            } as IUser;

        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
)



const slice = createSlice({
    name: 'authReducer',
    initialState,
    reducers: {
        setCurrentUserInfo(state:IUserState, action: PayloadAction<string>) {
            const decoded = jwt_decode(action.payload);
            state.user = decoded as IUser;
            console.log(action.payload)

        },
        userLogout(state:IUserState) {
            state.isAuthenticated = false;
            state.user = initialState.user;

            localStorage.removeItem('_token');

            // history.push("/banfondesa2/login")
            history.push("/login")

        },
    },
    extraReducers: builder => {
        //---mostrarPerfil
        builder.addCase(mostrarPerfil.pending, (state) => {
            state.status = "loading"
        })
        builder.addCase(mostrarPerfil.fulfilled, (state, action) => {
            state.status = "idle"
            state.isAuthenticated = true;
            state.user = action.payload;
        })
        builder.addCase(mostrarPerfil.rejected, (state) => {
            state.status = "idle"
        })

    }

});



export const {userLogout,setCurrentUserInfo} = slice.actions;
export const authReducer = slice.reducer;
