import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {ITheme} from '../types/ITheme';
import {getNenus} from "../../services";

const initialState: ITheme = {
    drawerOpen: false,
    searchBoxOpen: false,
    menus:undefined
}

export async function  getMenu(id : number) {
    let response = await getNenus(id);

    let m = (response.data?.data ?? [])?.map((d:any)=> ({
        id: d.id,
        ...d?.attributes,
        submenus:[

            ...d?.attributes.submenus.data?.map((m:any)=>{
                return {
                    id: m.id,
                    ...m.attributes,
                    submenus:[
                        ...m.attributes.submenus.data?.map((s:any)=>{
                            return {
                                id: s.id,
                                ...s.attributes
                            }
                        })
                    ]
                }
            })
        ]
    }))

   return m;
}

export const mostrarMenu = createAsyncThunk(
    'mostrarMenu',
    async (id: number) => {
      return getMenu(id );
    }
);


const slice = createSlice({
    name: 'themeSetting',
    initialState,
    reducers: {
        toggleDrawer(state) {
            state.drawerOpen = !state.drawerOpen;
        },
        toggleSearchBox(state) {
            state.searchBoxOpen = !state.searchBoxOpen;
        },
    },
    extraReducers: builder => {
        //---mostrarPerfil
        builder.addCase(mostrarMenu.pending, (state, action) => {
            // state.status = "loading"
        })
        builder.addCase(mostrarMenu.fulfilled, (state, action) => {
            // state.status = "idle"
            state.menus = action.payload;
        })
        builder.addCase(mostrarMenu.rejected, (state, action) => {
            // state.status = "idle"
        })

    }

});

export const {toggleDrawer, toggleSearchBox} = slice.actions;
export default slice.reducer;
