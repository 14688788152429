import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {mostrarPerfil} from "../../redux/reducers/authReducer";
import {useAppDispatch} from "../../redux/hooks";
import {API_PUBLIC} from "../../services";
import makeStyles from "@mui/styles/makeStyles";
import {Button, Typography} from "@mui/material";

const useStyles = makeStyles((theme) => ({

  root:{
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

}));


const LoginRedirect = () => {
  const classes = useStyles();

  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [error, setError] = useState("");

  useEffect(() => {

    API_PUBLIC.get(`/auth/${params.providerName}/callback${location.search}`)
      .then(({data}) => {

        localStorage.setItem("_token", data.jwt);
        localStorage.setItem("_lastUser", data.user.username);
        dispatch(mostrarPerfil(data.user));
        navigate('/inicio')

      })
      .catch(({response}) => {
        setError(response?.data?.error?.message ?? 'Se produjo un error; consulte la consola del desarrollador.')
      });
  }, [dispatch,setError, navigate, location.search, params.providerName]);

  return <>
    <div className={classes.root}>
      <div className="fof">
        <Typography variant={"h5"}>{ error ?? "Cargando..." }</Typography>
        {!!error &&
            <Button href={"/"} variant={"outlined"} sx={{mt:2}}>Ir al inicio</Button>
        }
      </div>
    </div>
  </>
};

export default LoginRedirect;
