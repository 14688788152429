import {authReducer} from './authReducer';
import {combineReducers} from '@reduxjs/toolkit'
import {reducer as notifications} from 'react-notification-system-redux';
import theme from './themeReducer';
import favoritos from './favoritosReducer';
import notificaciones from './notificacionesReducer';

const combinedReducer = combineReducers({
    auth: authReducer,
    theme,
    favoritos,
    notificaciones,
    notifications,
})

const rootReducer = (state:any, action:any) => {
    if (action.type === 'authReducer/userLogout') {
        state = undefined
    }
    return combinedReducer(state, action)
}

export type AppState = ReturnType<typeof rootReducer>

export default rootReducer
