import errorToNotifObj from "./errorToNotif"
import moment from 'moment';
import "moment/min/locales.min"
import {PERSMISOS, TIPO_USUARIO, mapApiOptions} from "./CONSTANTES"
import {getTokenInfo} from "../services/auth";
import Strings from "../assets/strings";

export function getLSLang(tipo) {
    const langLS = localStorage.getItem('lang');
   // const browserLang = navigator.language.split('-')[0];
    let defaultLang = "es";
    let defaultCountry = "";

    //si el idioma del navegador es soportado sera el idioma por defecto
    //if(Strings.getAvailableLanguages().includes(browserLang)) defaultLang = browserLang;

    //valor por defecto por si no esta guardado
    var response = tipo?(tipo === "l" ? defaultLang :defaultCountry):defaultLang+"-"+defaultCountry;


    if(langLS && langLS.split("-").length === 2){
        switch (tipo) {
            case "l":
                response = langLS.split("-")[0];
              break;
            case "c":
                response = langLS.split("-")[1];
                break;
            default:
                response = langLS;
                break;
        }
    }else{
        //si no esta setearlo
        localStorage.setItem("lang",defaultLang+"-"+defaultCountry)
    }
    return response;
}
moment.locale(getLSLang("l"));
export function setLSLang(language,country) {
    const langLS = localStorage.getItem('lang');
    let lang = "es";
    let count = "";

    //set language
    if(language) {
        lang = language;
    }else if(langLS && langLS.split("-").length === 2){
        lang = langLS.split("-")[0];
    }
    //set country
    if(country) {
        count = country;
    }else if(language && langLS && langLS.split("-").length === 2){
        count = langLS.split("-")[1];
    }

    localStorage.setItem("lang", lang+"-"+count)
}
export function formatDate(date, format="DD/MM/YYYY") { //h:mm a
    if(!date)
        return "";

   return moment(date).format(format);

    // format = "DD/MM/YYYY";
    //
    // let fecha =  moment(date).calendar(null,{
    //     // lastDay : '[Yesterday]',
    //     // sameDay : '[Hoy]',
    //     // nextDay : '[Tomorrow]',
    //     lastWeek : format,
    //     nextWeek : format,
    //     sameElse : format});
    //
    // fecha = fecha.includes("a la") || fecha.includes("at") ? fecha.split(" ")[0] : fecha;
    //
    // return fecha.charAt(0).toUpperCase() + fecha.slice(1)


}
export {moment};
export function formatPeso(val,maxFraction = 2) {
    let value = val ? parseFloat(val): 0;
    let countryCode = "";
    const lSCountryCode = getLSLang("c");
    if (lSCountryCode) countryCode = lSCountryCode;

    const paises = [
        {paiCodigo: "DO",paiModeneda: "DOP", paiIdioma:"es"},
        {paiCodigo: "US",paiModeneda: "USA", paiIdioma:"en"},
        {paiCodigo: "ES",paiModeneda: "EUR", paiIdioma:"es"},
        {paiCodigo: "BO",paiModeneda: "BOB", paiIdioma:"es"},
        {paiCodigo: "CO",paiModeneda: "COP", paiIdioma:"es"},
        {paiCodigo: "CR",paiModeneda: "CRC", paiIdioma:"es"},
        {paiCodigo: "PY",paiModeneda: "PYG", paiIdioma:"es"},
        {paiCodigo: "GT",paiModeneda: "GTQ", paiIdioma:"es"},
        {paiCodigo: "VE",paiModeneda: "VES", paiIdioma:"es"},
        {paiCodigo: "PE",paiModeneda: "PEN", paiIdioma:"es"},
        {paiCodigo: "HN",paiModeneda: "HNL", paiIdioma:"es"},
        {paiCodigo: "NI",paiModeneda: "NIO", paiIdioma:"es"},
        {paiCodigo: "PA",paiModeneda: "PAB", paiIdioma:"es"},
        {paiCodigo: "MX",paiModeneda: "MXN", paiIdioma:"es"},
        {paiCodigo: "AR",paiModeneda: "ARS", paiIdioma:"es"},
        {paiCodigo: "CL",paiModeneda: "CLP", paiIdioma:"es"},
        {paiCodigo: "UY",paiModeneda: "UYU", paiIdioma:"es"}
    ];
    let monedaDisponible = paises.find(p=>p.paiCodigo === countryCode.toUpperCase());
    //si no se encuentra la moneda poner una universal
    if(!monedaDisponible){
        return '$' + parseFloat(value)
            .toFixed(maxFraction)
            .replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
    }

    //  if (countryCode && typeof Intl == 'object' && typeof Intl.NumberFormat == 'function' && monedaDisponible){
        return value?.toLocaleString(monedaDisponible.paiIdioma+"-"+monedaDisponible.paiCodigo, {
            style: 'currency',
            currency: monedaDisponible.paiModeneda,
            minimumFractionDigits: 0,
            maximumFractionDigits: maxFraction,
        }).replace("DOP","$");
   // }


}

export function removeEmptyValues(obj) {
    let newObj = {};
    Object.keys(obj).forEach((prop) => {
      if (obj[prop]) { newObj[prop] = obj[prop]; }
    });
    return newObj;
}
export function nullToEmptyString(obj,noCero) {
    let newObj = {};
    Object.keys(obj).forEach((prop) => {
        newObj[prop] = (obj[prop] === null || (noCero && obj[prop] === 0))  ? "" : obj[prop];
    });
    return newObj;
}


export function getPermisosList() {
    const {Permisos} = getTokenInfo();
    let permisosList = [];
    if(Permisos && Permisos.length > 1){
        try {
            permisosList = Permisos.split(",").map(r=>parseInt(r));
        }catch (e) {
            throw e;
        }
    }
    return permisosList
}
export function hasPermissionTo( idMenu, idPermiso) {
    return true
    // if(idPermiso){
    //     let menu = store.getState()?.auth?.user?.permisos
    //     let item = menu.find(m=>m.idMenu === idMenu)
    //     return !!item?.permisoOtorgado
    // }else{
    //     let menu = store.getState()?.auth?.user?.menu
    //     let item = menu.find(m=>m.idMenu === idMenu && m.idPermiso === idPermiso)
    //     return !!item?.accesoOtorgado
    // }

   // return getPermisosList().indexOf(idperId) !== -1;
}

export function urlPramsToObj(querystring){
    // parse query string
    const params = new URLSearchParams(querystring);

    let obj = {};

    // iterate over all keys
    for (const key of params.keys()) {
        if (params.getAll(key).length > 1) {
            obj[key] = params.getAll(key);
        } else {
            obj[key] = params.get(key);
        }
    }

    return obj;
}
export {errorToNotifObj,PERSMISOS,TIPO_USUARIO, mapApiOptions}

export function translateString(string="") {
    if (!string || !string.length) return "";
    let value = string.charAt(0).toLowerCase() + string.slice(1);

    if(Strings[value]) {
        return Strings[value];
    }

    return value;
}
