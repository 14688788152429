import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { store } from './redux/store';
import App from './App';
//import registerServiceWorker from './registerServiceWorker';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
//import reportWebVitals from './reportWebVitals';


//auth -----------------------
import {mostrarPerfil } from './redux/reducers/authReducer';
import Strings from "./assets/strings";
import {getLSLang} from "./helpers";
import {MsalProvider} from "@azure/msal-react";
import {PublicClientApplication} from "@azure/msal-browser";
import {msalConfig} from "./helpers/microsoftConfig";
//end auth -----------------------





const msalInstance = new PublicClientApplication(msalConfig);

//import * as serviceWorker from './serviceWorker';

if(localStorage._token) {
    store.dispatch(mostrarPerfil(undefined));
}
//setear idioma
let lang = getLSLang("l");
Strings.setLanguage(lang);

if (!Object.entries) {
    Object.entries = function( obj:any ){
        var ownProps = Object.keys( obj ),
            i = ownProps.length,
            resArray = new Array(i); // preallocate the Array
        while (i--)
            resArray[i] = [ownProps[i], obj[ownProps[i]]];

        return resArray;
    };
}


ReactDOM.render(
  //<React.StrictMode>
    <Provider store={store}>
        <MsalProvider instance={msalInstance}>
            <App />
        </MsalProvider>
    </Provider>,
  //</React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();


//registerServiceWorker();

// If you want to start measuring performance in your app, pass a function
// to log items (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();


