export const colors = [
    {primary: { main: "#000E77"}, secondary: { main:"#F58823"}},
]
export function getColor(color) {

    let foundColor = colors.find(c => c.primary.main === color);
    if(foundColor) return foundColor;

    return colors[0]
}
